import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import logo from "../images/logo_tanski.png"
import "../components/header.css"

const Header = ({ siteTitle }) => (
  <header>
    <div className="container">
      <div className="header">
        <Link to="/" >
          <img src={logo} alt="Tanski Tecnologia"/>
        </Link>

        <nav>
          <ul>
            <li>
              <Link to="/#services">Serviços</Link>
            </li>
          </ul>
        </nav>

        <Link to="/#contact">
        <div className="header-button">
            <span>contato</span>
        </div>
        </Link>
      </div>  
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
