import React from "react"
import { Link } from "gatsby"
import { FaArrowDown } from "react-icons/fa"
import "../components/slides.css"

const Slides = () => (
  <div className="slides">
    <div className="container">
        <div className="slide">
            <h1>Soluções baseadas em Asterisk</h1>
            <p>Somos especialistas no desenvolvimento de sistemas de telefonia.</p>
            {/* <button>Saiba mais</button> */}
        </div>
    </div>
    <Link to="/#services">
      <FaArrowDown size={20} color="#fff" className="slide-icon" />
    </Link>
  </div>
)

export default Slides
