import React from "react"
import { Link } from "gatsby"
import { FaFacebookSquare, FaInstagram } from "react-icons/fa"

import logo from "../images/logo_tanski.png"
import "../components/footer.css"

const Footer = () => (
  <footer>
    <div className="container">
      <div className="footer-top">
      
        <img src={logo} alt="Tanski Tecnologia"/>

        <aside>
          
          <div>
            <h4>Serviços</h4>
            <ul>
              <li>
                <Link to="/#services">Suporte Técnico</Link>
              </li>
              <li>
                <Link to="/#services">Consultoria</Link>
              </li>
              <li>
                <Link to="/#services">Treinamentos</Link>
              </li>
            </ul>
          </div>
       
          <div>
            <h4>Redes Sociais</h4>
            <div className="social">
              <a href="http://www.facebook.com/tanski.tecnologia" target="_blank" rel="noopener noreferrer">
                <FaFacebookSquare size={20} />
              </a>
            </div>
            <div className="social">
                <a href="https://www.instagram.com/tanskitecnologia" target="_blank" rel="noopener noreferrer">
                <FaInstagram size={20} />
              </a>
            </div>
          </div>

          <div>
            <h4>Fale Conosco</h4>
            <ul>
              <li>
                <a href="mailto:faleconosco@tanski.com.br">contato@tanski.com.br</a>
              </li>
            </ul>
          </div>
        </aside>
      </div>
    </div>

    <div className="footer-bottom">
      <span>Tanski Tecnologia. Todos os direitos reservados @ 2020</span>
    </div>
  </footer>
)

export default Footer
